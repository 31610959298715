body{
    background-color:#F5F7F9;
}
.obrigada{
  font-size: 120px;
}
.ant-col {
  margin: 0;
}
.ant-form-item{
    margin-bottom: 24px;
}
.ant-card-bordered {
  border: 1px solid #f0f0f0;
  border-radius: 10px;
  margin-bottom: 15px;
}
.wrap{
-webkit-box-shadow: 0px 0px 7px 0px rgba(0,0,0,0.36);
-moz-box-shadow: 0px 0px 7px 0px rgba(0,0,0,0.36);
box-shadow: 0px 0px 7px 0px rgba(0,0,0,0.36);
text-align: -moz-center;
text-align: -webkit-center;
margin-top: 5vh;
height: 90vh;
width: 890px !important;
flex: inherit !important;
}
.formPage{
    height: 50vh;
}

@media only screen and (min-width: 571px) and (max-width: 850px){
    .cont_error {
        top: 55% !important;
      }
    .cont_error > h1 {
        font-size: 130px !important;
        line-height: 1 !important;
        margin: 0 !important;
      }
      .cont_error > p {
        font-size: 26px !important;
        letter-spacing: 2px !important;
      }
      .wrap{
        margin-top: 0vh;
        height: 100vh;
        }
    .ant-col-20 {
        display: block;
        flex: 0;
        max-width: 100%;
    }
    
 .formPage{
    height: 45vh !important;
 }
}

@media only screen and (min-width: 450px) and (max-width: 570px){
  .obrigada{
    font-size: 80px;
  }
    .cont_error {
        top: 30% !important;
      }
    .cont_error > h1 {
        font-size: 100px !important;
        margin: 0 !important;
      }
      .cont_error > p {
        font-size: 19px !important;
        letter-spacing: 2px !important;
      }
    .wrap{
        margin-top: 0vh;
        height: 100vh;
        }
    .ant-col-20 {
        display: block;
        flex: 0;
        max-width: 100%;
    }
 .formPage{
    height: 18vh !important;
 }   
}

@media only screen and (min-width: 396px) and (max-width: 449px){
  .obrigada{
    font-size: 60px;
  }
    .cont_error {
        top: 30% !important;
      }
    .cont_error > h1 {
        font-size: 57px !important;
        margin: 0 !important;
        line-height: 1 !important;
      }
      .cont_error > p {
        font-size: 13px !important;
        letter-spacing: 2px !important;
      }
    .wrap{
        margin-top: 0vh;
        height: 100vh;
        }
    .ant-col-20 {
        display: block;
        flex: 0;
        max-width: 100%;
    }
    .ant-col {
      margin-left: 15px;
    }
 .formPage{
    height: 0vh !important;
 }   

}

@media only screen and (min-width: 0px) and (max-width: 395px){
  .obrigada{
    font-size: 50px;
  }
    .logo-puzli {
        width: 146% !important;
        right: -23% !important;
        bottom: -216px !important;
      }
      .cont_aura_2 {
        width: 146% !important;
        right: -23% !important;
        bottom: -216px !important;
      }
    .cont_error {
        top: 30% !important;
      }
    .cont_error > h1 {
        font-size: 57px !important;
        margin: 0 !important;
        line-height: 1 !important;
      }
      .cont_error > p {
        font-size: 13px !important;
        letter-spacing: 2px !important;
      }
      .wrap{
        margin-top: 0vh;
        height: 100vh;
        }
    .ant-col-20 {
        display: block;
        flex: 0;
        max-width: 100%;
    }
    .ant-form-item-control-input-content {
        flex: auto;
        max-width: 100%;
        margin-left: 15px;
      }
      .ant-radio-button-wrapper.ant-radio-button-wrapper-in-form-item {
        width: 100px !important;
      }
      .ant-col.ant-col-24.ant-form-item-label {
        margin-left: 15px;
      }
      .loading{
        font-size: 180px !important;
      }
 .formPage{
    height: 0vh !important;
 }   

}
